/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
   RESET CSS
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* START CUSTOM CSS*/

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");

html {
  font-family: "Roboto", sans-serif;
}

h1 {
  text-align: center;
  font-size: 2em;
  text-decoration: underline;
  text-underline-offset: 10px;
  text-decoration-color: var(--persian-green);
  transition: text-decoration-color 500ms;
  text-decoration-thickness: 4px;
  text-decoration-style: solid;
  margin-bottom: 40px;
}

h1:hover {
  text-decoration-color: var(--orange-yellow-crayola);
}

p {
  text-align: left;
  font-size: 1.3em;
  line-height: 2em;
  margin-bottom: 1em;
}

a {
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-color: var(--charcoal);
  transition: text-decoration-color 0.5s ease;
}

a:hover {
  text-decoration-color: var(--sandy-brown);
}
