:root {
  /*https://coolors.co/03045e-023e8a-0077b6-0096c7-00b4d8-48cae4-90e0ef-ade8f4-caf0f8*/
  /* COLORS */
  --color1: #03045eff;
  --color2: #023e8aff;
  --color3: #0077b6ff;
  --color4: #0096c7ff;
  --color5: #00b4d8ff;
  --color6: #48cae4ff;
  --color7: #90e0efff;
  --color8: #ade8f4ff;
  --color9: #caf0f8ff;

  --black: black;
  --white: white;
  --grey: rgb(189, 181, 181);

  --charcoal: #264653ff;
  --persian-green: #2a9d8fff;
  --orange-yellow-crayola: #e9c46aff;
  --sandy-brown: #f4a261ff;
  --burnt-sienna: #e76f51ff;

  /* GRADIENTS */
  --blue-gradient: linear-gradient(
    to right,
    var(--charcoal),
    var(--persian-green)
  );

  --orange-gradient: linear-gradient(
    to right,
    var(--orange-yellow-crayola),
    var(--sandy-brown)
  );

  --black-gradient: linear-gradient(to right, var(--black), var(--grey));

  /* DROP SHADOWS */
  --green-box-shadow: 0 1px 10px -2px var(--persian-green);
  --orange-box-shadow: 0 1px 10px -2px var(--sandy-brown);
  --black-box-shadow: 0 1px 5px -2px var(--grey);

  --big-green-box-shadow: 0 1px 20px -2px var(--persian-green);
}
