/* NORMAL PAGE SIZE */
.main-content {
  min-width: 300px;
  padding: 30px;
  max-width: 800px;
  display: block;
  margin: 0 auto;
}

.full-width {
  min-width: 300px;
  padding: 30px;
  max-width: 100vw;
  display: block;
}

.page-background {
  background: var(--gradient, white);
}

/* TEXT PAGE: every page with text content as the most important element */
.text-page {
  min-width: 300px;
  padding: 30px;
  max-width: 800px;
  display: block;
  margin: 0 auto;
}

/* FORM PAGE: every page with Typeform as main content */
.form-page {
  padding: 30px;
  max-width: 100%;
  height: 100%;
  display: block;
  margin-top: 0;
}

/* HOME */
.home-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 300px));
  gap: 2rem;
  justify-content: center;
}

.home-grid-item {
  display: grid;
  place-items: center;
  width: 100%;
  position: relative;
}

.home-grid-item::before {
  content: "";
  padding-top: 100%;
  display: block;
}

.grid-content {
  position: absolute;
  height: 100%;
  width: 100%;
}

/* .home-grid-item {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
} */

.home-headline {
  font-size: 2.5em;
  margin-bottom: 45px;
}

@media (max-width: 768px) {
  .home-headline {
    font-size: 1.5em;
  }
}
