@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
   RESET CSS
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* START CUSTOM CSS*/

html {
  font-family: "Roboto", sans-serif;
}

h1 {
  text-align: center;
  font-size: 2em;
  text-decoration: underline;
  text-underline-offset: 10px;
  -webkit-text-decoration-color: var(--persian-green);
          text-decoration-color: var(--persian-green);
  transition: -webkit-text-decoration-color 500ms;
  transition: text-decoration-color 500ms;
  transition: text-decoration-color 500ms, -webkit-text-decoration-color 500ms;
  text-decoration-thickness: 4px;
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
  margin-bottom: 40px;
}

h1:hover {
  -webkit-text-decoration-color: var(--orange-yellow-crayola);
          text-decoration-color: var(--orange-yellow-crayola);
}

p {
  text-align: left;
  font-size: 1.3em;
  line-height: 2em;
  margin-bottom: 1em;
}

a {
  text-decoration: underline;
  text-underline-offset: 5px;
  -webkit-text-decoration-color: var(--charcoal);
          text-decoration-color: var(--charcoal);
  transition: -webkit-text-decoration-color 0.5s ease;
  transition: text-decoration-color 0.5s ease;
  transition: text-decoration-color 0.5s ease, -webkit-text-decoration-color 0.5s ease;
}

a:hover {
  -webkit-text-decoration-color: var(--sandy-brown);
          text-decoration-color: var(--sandy-brown);
}

:root {
  /*https://coolors.co/03045e-023e8a-0077b6-0096c7-00b4d8-48cae4-90e0ef-ade8f4-caf0f8*/
  /* COLORS */
  --color1: #03045eff;
  --color2: #023e8aff;
  --color3: #0077b6ff;
  --color4: #0096c7ff;
  --color5: #00b4d8ff;
  --color6: #48cae4ff;
  --color7: #90e0efff;
  --color8: #ade8f4ff;
  --color9: #caf0f8ff;

  --black: black;
  --white: white;
  --grey: rgb(189, 181, 181);

  --charcoal: #264653ff;
  --persian-green: #2a9d8fff;
  --orange-yellow-crayola: #e9c46aff;
  --sandy-brown: #f4a261ff;
  --burnt-sienna: #e76f51ff;

  /* GRADIENTS */
  --blue-gradient: linear-gradient(
    to right,
    var(--charcoal),
    var(--persian-green)
  );

  --orange-gradient: linear-gradient(
    to right,
    var(--orange-yellow-crayola),
    var(--sandy-brown)
  );

  --black-gradient: linear-gradient(to right, var(--black), var(--grey));

  /* DROP SHADOWS */
  --green-box-shadow: 0 1px 10px -2px var(--persian-green);
  --orange-box-shadow: 0 1px 10px -2px var(--sandy-brown);
  --black-box-shadow: 0 1px 5px -2px var(--grey);

  --big-green-box-shadow: 0 1px 20px -2px var(--persian-green);
}

/* NAVBAR */
.navbar {
  height: 50px;
  width: 100%;
  background-color: var(--white);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 1em;
  padding-bottom: 1em;
  color: var(--white);
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: var(--black-box-shadow);
}

.navbar a {
  text-decoration: none;
}

.nav-menu {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  margin-right: 3rem;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  background-color: white;
}

.nav-item {
  list-style: none;
  margin-left: 3rem;
  transition: box-shadow 0.6s ease;
}

.nav-link {
  color: var(--black);
  transition: color 0.5s ease, background 0.5s ease;
  padding: 10px;
}

.logo-image {
  width: 150px;
  margin-top: 5px;
  padding: 20px;
}

.mobile-menu-icon {
  display: none;
}

/* DESKTOP */
@media screen and (min-width: 960px) {
  .nav-link:hover {
    background: var(--persian-green);
    color: var(--white);
  }

  .external-link:hover {
    background: var(--persian-green);
    color: var(--white);
  }
}

/* HAMBURGER MENU */
@media screen and (max-width: 1200px) {
  .navbar {
    display: -webkit-flex;
    display: flex;
  }

  .nav-menu {
    display: none;
  }

  .nav-menu-active {
    display: block;
    -webkit-flex-direction: column;
            flex-direction: column;
    background-color: var(--persian-green);
    width: 100%;
    position: absolute;
    box-shadow: var(--standard-box-shadow);
    margin-top: 0;
    top: 80px;
    transition: top 1s ease;
    z-index: 1;
    padding-bottom: 20px;
  }

  .nav-item {
    margin-left: 0;
    text-align: center;
    font-size: 2em;
    padding: 40px;
    -webkit-text-decoration-color: var(--burnt-sienna);
            text-decoration-color: var(--burnt-sienna);
  }

  .nav-link {
    color: white;
  }

  .nav-item:hover {
    text-decoration: underline;
    text-underline-offset: 7px;
    -webkit-text-decoration-color: var(--burnt-sienna);
            text-decoration-color: var(--burnt-sienna);
    transition: -webkit-text-decoration 0.5s ease;
    transition: text-decoration 0.5s ease;
    transition: text-decoration 0.5s ease, -webkit-text-decoration 0.5s ease;
  }

  .mobile-menu-icon {
    display: block;
    position: absolute;
    color: black;
    font-size: 3em;
    top: 0px;
    right: 0px;
    padding: 20px;
  }
}

.card {
  display: -webkit-flex;
  display: flex;
  aspect-ratio: 1;
  margin: 20px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  border-radius: 10px;
  color: var(--white);
  box-shadow: var(--black-box-shadow);
  transition: all 0.6s ease;
}

.card-orange-gradient {
  background: var(--orange-gradient);
}

.card-blue-gradient {
  background: var(--blue-gradient);
}

.black-gradient {
  --gradient: var(--black-gradient);
}

.card:hover .card-title,
.card:hover .card-text {
  display: block;
}

.card:hover .card-icon {
  display: none;
}

.card:hover .card-title {
  position: static;
  font-size: 1.5em;
  -webkit-align-self: center;
          align-self: center;
  text-decoration: underline;
  text-underline-offset: 10px;
  -webkit-text-decoration-color: var(--persian-green);
          text-decoration-color: var(--persian-green);
  transition: opacity 0.6s, -webkit-text-decoration-color 500ms;
  transition: text-decoration-color 500ms, opacity 0.6s;
  transition: text-decoration-color 500ms, opacity 0.6s, -webkit-text-decoration-color 500ms;
  text-decoration-thickness: 4px;
  -webkit-text-decoration-style: solid;
          text-decoration-style: solid;
  background: none;
  margin-bottom: 40px;
  margin-left: -0px;
  margin-top: 0px;
  -webkit-transform: revert;
          transform: revert;
  width: inherit;
  color: white;
  font-weight: normal;
}

.card:hover {
  -webkit-justify-content: center;
          justify-content: center;
}

.card-title {
  position: absolute;
  font-size: 1em;
  -webkit-align-self: flex-start;
          align-self: flex-start;
  text-align: center;
  color: black;
  font-weight: bold;
  background: var(--orange-yellow-crayola);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 0px;
  margin-left: -37px;
  margin-top: 3px;
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
  width: 187px;
}

.card-text {
  display: none;
  text-align: center;
  font-size: 1em;
  line-height: 30px;
  margin-bottom: 20px;
}

.card-icon {
  font-size: 10em;
  transition: all 0.5s ease;
  margin: auto;
}

.card-link {
  text-decoration: none;
}

/* @media screen and (max-width: 600px) {
  .card-icon {
    font-size: 10em;
  }

  .card:hover .card-title {
    font-size: 25px;
  }
}
 */

/* NORMAL PAGE SIZE */
.main-content {
  min-width: 300px;
  padding: 30px;
  max-width: 800px;
  display: block;
  margin: 0 auto;
}

.full-width {
  min-width: 300px;
  padding: 30px;
  max-width: 100vw;
  display: block;
}

.page-background {
  background: white;
  background: var(--gradient, white);
}

/* TEXT PAGE: every page with text content as the most important element */
.text-page {
  min-width: 300px;
  padding: 30px;
  max-width: 800px;
  display: block;
  margin: 0 auto;
}

/* FORM PAGE: every page with Typeform as main content */
.form-page {
  padding: 30px;
  max-width: 100%;
  height: 100%;
  display: block;
  margin-top: 0;
}

/* HOME */
.home-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 300px));
  grid-gap: 2rem;
  gap: 2rem;
  -webkit-justify-content: center;
          justify-content: center;
}

.home-grid-item {
  display: grid;
  place-items: center;
  width: 100%;
  position: relative;
}

.home-grid-item::before {
  content: "";
  padding-top: 100%;
  display: block;
}

.grid-content {
  position: absolute;
  height: 100%;
  width: 100%;
}

/* .home-grid-item {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
} */

.home-headline {
  font-size: 2.5em;
  margin-bottom: 45px;
}

@media (max-width: 768px) {
  .home-headline {
    font-size: 1.5em;
  }
}

