.card {
  display: flex;
  aspect-ratio: 1;
  margin: 20px;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  color: var(--white);
  box-shadow: var(--black-box-shadow);
  transition: all 0.6s ease;
}

.card-orange-gradient {
  background: var(--orange-gradient);
}

.card-blue-gradient {
  background: var(--blue-gradient);
}

.black-gradient {
  --gradient: var(--black-gradient);
}

.card:hover .card-title,
.card:hover .card-text {
  display: block;
}

.card:hover .card-icon {
  display: none;
}

.card:hover .card-title {
  position: static;
  font-size: 1.5em;
  align-self: center;
  text-decoration: underline;
  text-underline-offset: 10px;
  text-decoration-color: var(--persian-green);
  transition: text-decoration-color 500ms, opacity 0.6s;
  text-decoration-thickness: 4px;
  text-decoration-style: solid;
  background: none;
  margin-bottom: 40px;
  margin-left: -0px;
  margin-top: 0px;
  transform: revert;
  width: inherit;
  color: white;
  font-weight: normal;
}

.card:hover {
  justify-content: center;
}

.card-title {
  position: absolute;
  font-size: 1em;
  align-self: flex-start;
  text-align: center;
  color: black;
  font-weight: bold;
  background: var(--orange-yellow-crayola);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 0px;
  margin-left: -37px;
  margin-top: 3px;
  transform: rotate(-10deg);
  width: 187px;
}

.card-text {
  display: none;
  text-align: center;
  font-size: 1em;
  line-height: 30px;
  margin-bottom: 20px;
}

.card-icon {
  font-size: 10em;
  transition: all 0.5s ease;
  margin: auto;
}

.card-link {
  text-decoration: none;
}

/* @media screen and (max-width: 600px) {
  .card-icon {
    font-size: 10em;
  }

  .card:hover .card-title {
    font-size: 25px;
  }
}
 */
