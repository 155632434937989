@import "Colors.css";

/* NAVBAR */
.navbar {
  height: 50px;
  width: 100%;
  background-color: var(--white);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 1em;
  color: var(--white);
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: var(--black-box-shadow);
}

.navbar a {
  text-decoration: none;
}

.nav-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-right: 3rem;
  justify-content: flex-end;
  align-items: center;
  background-color: white;
}

.nav-item {
  list-style: none;
  margin-left: 3rem;
  transition: box-shadow 0.6s ease;
}

.nav-link {
  color: var(--black);
  transition: color 0.5s ease, background 0.5s ease;
  padding: 10px;
}

.logo-image {
  width: 150px;
  margin-top: 5px;
  padding: 20px;
}

.mobile-menu-icon {
  display: none;
}

/* DESKTOP */
@media screen and (min-width: 960px) {
  .nav-link:hover {
    background: var(--persian-green);
    color: var(--white);
  }

  .external-link:hover {
    background: var(--persian-green);
    color: var(--white);
  }
}

/* HAMBURGER MENU */
@media screen and (max-width: 1200px) {
  .navbar {
    display: flex;
  }

  .nav-menu {
    display: none;
  }

  .nav-menu-active {
    display: block;
    flex-direction: column;
    background-color: var(--persian-green);
    width: 100%;
    position: absolute;
    box-shadow: var(--standard-box-shadow);
    margin-top: 0;
    top: 80px;
    transition: top 1s ease;
    z-index: 1;
    padding-bottom: 20px;
  }

  .nav-item {
    margin-left: 0;
    text-align: center;
    font-size: 2em;
    padding: 40px;
    text-decoration-color: var(--burnt-sienna);
  }

  .nav-link {
    color: white;
  }

  .nav-item:hover {
    text-decoration: underline;
    text-underline-offset: 7px;
    text-decoration-color: var(--burnt-sienna);
    transition: text-decoration 0.5s ease;
  }

  .mobile-menu-icon {
    display: block;
    position: absolute;
    color: black;
    font-size: 3em;
    top: 0px;
    right: 0px;
    padding: 20px;
  }
}
